<template>
  <div id="fullroot" class="full" style="background-color:white;height:100vh;overflow-y:scroll" v-on:scroll.passive="handleScroll">
    <mobile-nav-top v-if="_mobile" :obj="$store.state.AppActiveUser"></mobile-nav-top>
    <nav-top v-else :obj="$store.state.AppActiveUser" :reverse="reverse"></nav-top>
    <vue-page-transition name="fade-in-right">
    <div class="main-full" v-bind:class="[ _mobile  ? 'mobile' : 'pc' ]" ><router-view></router-view></div>
    </vue-page-transition>
  </div>
</template>


<script>
import NavTop from "../nav/NewNavTop";
import MobileNavTop from "../nav/MobileNavTop";
import { isMobile, isIE } from "../../views/components/fnc.js";

export default {
  data() {
    return {
      reverse: false
    };
  },
  components: {
    NavTop,
    MobileNavTop
  },
  computed: {
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      return _mobile;
    }
  },
  mounted() {
    if (isIE()) {
      this.$router.push("/chromecheck");
    }
  },
  methods: {
    handleScroll(e) {
      if (this.$route.name == "home") {
        if (e.target.scrollTop < 10) {
          this.reverse = false;
        } else {
          this.reverse = true;
        }
      } else {
        this.reverse = false;
      }
      // console.log(this.$route.name, e.target.scrollTop);
    }
  }
};
</script>

<style>
.main-full.pc {
  width: 100%;
  margin-top: 120px;
}

.main-full.mobile {
  width: 100%;
  margin-top: 60px;
}
</style>
<template>
  <div
    style="
      position: fixed;
      z-index: 10;
      width: 100%;
      background-color: white;
      min-width: 1090px;
    "
  >
    <div
      style="
        width: 100%;
        height: 58px;
        background-color: white;
        border-bottom: 1px solid #e0e0e0;
      "
      v-show="!reverse"
    >
      <div style="justify-content: flex-end; align-items: flex-end">
        <div
          style="
            display: flex;
            padding: 20px;
            float: right;
            right: 0;
            text-align: right;
          "
        >
          <div style="display: flex">
            <div class="s-top-menu1" @click="onDownload()">서비스 소개서</div>
            <div class="s-top-menu1-div"></div>

            <div class="s-top-menu1">
              <a-dropdown>
                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                  서비스 안내&nbsp;<i class="fas fa-caret-down"></i>
                </a>
                <a-menu slot="overlay">
                  <a-menu-item @click="invokeChannelTalk()"
                    >고객센터</a-menu-item
                  >
                  <a-menu-item
                    @click="$router.push('/pages/info').catch(() => {})"
                    >공지사항</a-menu-item
                  >
                  <a-menu-item
                    @click="$router.push('/pages/price').catch(() => {})"
                    >이용요금</a-menu-item
                  >
                </a-menu>
              </a-dropdown>
            </div>
            <div class="s-top-menu1-div" v-if="!obj.displayName"></div>
            <div
              class="s-top-menu1"
              v-if="!obj.displayName"
              @click="$router.push('/pages/login').catch(() => {})"
            >
              로그인
            </div>
            <div class="s-top-menu1-div" v-if="!obj.displayName"></div>
            <div
              class="s-top-menu1"
              v-if="!obj.displayName"
              @click="$router.push('/pages/register').catch(() => {})"
            >
              회원가입
            </div>
            <div class="s-top-menu1-div"></div>
            <div class="s-top-menu1" @click="onLogOut()">초기화</div>
          </div>
        </div>
      </div>
    </div>

    <a-page-header class="navtop" v-bind:class="[reverse ? 'reverse' : '']">
      <template slot="title">
        <div style="margin-top: 3px">
          <img
            style="width: 95px; cursor: pointer"
            @click="onBack()"
            src="@/assets/images/v4-icon/slogo.png"
          />
          <span style="color: #333333; font-size: 10px; padding-left: 10px"
            >v {{ ver() }}</span
          >
        </div>
      </template>
      <template slot="extra">
        <div class="navtop">
          <a-menu v-model="current" mode="horizontal">
            <a-sub-menu>
              <span slot="title" class="submenu-title-wrapper">
                키워드분석&nbsp;<i class="fas fa-caret-down"></i
              ></span>
              <a-menu-item
                class="font-menu1"
                key="keyword:1"
                @click="$router.push('/pages/keyword/find').catch(() => {})"
              >
                키워드찾기
              </a-menu-item>
              <!-- <a-menu-item class="font-menu1" key="keyword:2" @click="$router.push('/pages/keyword/my').catch(() => {})">
                            나의키워드
                        </a-menu-item> -->
              <a-menu-item
                class="font-menu1"
                key="up:2"
                @click="$router.push('/pages/friday').catch(() => {})"
              >
                키워드분석
              </a-menu-item>
              <!-- <a-menu-item class="font-menu1" key="up:3" @click="$router.push('/pages/friday2').catch(() => {})">
                            키워드분석
                        </a-menu-item> -->
            </a-sub-menu>

            <a-sub-menu>
              <span slot="title" class="submenu-title-wrapper">
                상위노출&nbsp;<i class="fas fa-caret-down"></i
              ></span>
              <a-menu-item
                class="font-menu1"
                key="up:1"
                @click="$router.push('/pages/top/analysis').catch(() => {})"
              >
                상위노출 전략분석
              </a-menu-item>
              <a-menu-item
                v-if="getIncludeKeyword == 'T'"
                class="font-menu1"
                key="up:3"
                @click="$router.push('/pages/keyword').catch(() => {})"
              >
                키워드상세분석(영업)
              </a-menu-item>
              <a-menu-item
                class="font-menu1"
                key="store:1"
                @click="$router.push('/pages/store/analysis').catch(() => {})"
              >
                상위노출 제품분석
              </a-menu-item>
            </a-sub-menu>

            <a-sub-menu v-if="obj.displayName">
              <span slot="title" class="submenu-title-wrapper">
                순위관리&nbsp;<i class="fas fa-caret-down"></i
              ></span>
              <a-menu-item
                class="font-menu1"
                key="real:1"
                @click="$router.push('/pages/overview').catch(() => {})"
              >
                실시간검색
              </a-menu-item>
              <a-menu-item
                class="font-menu1"
                key="real:2"
                @click="$router.push('/pages/favorite').catch(() => {})"
              >
                제품관리
              </a-menu-item>
              <a-menu-item
                class="font-menu1"
                v-if="getIsAgency == 'T'"
                key="real:3"
                @click="$router.push('/pages/advertise').catch(() => {})"
              >
                광고주관리
              </a-menu-item>
            </a-sub-menu>

            <a-sub-menu v-if="obj.displayName">
              <span slot="title" class="submenu-title-wrapper">
                상위노출캠페인&nbsp;<i class="fas fa-caret-down"></i
              ></span>
              <a-menu-item
                class="font-menu1"
                key="apply:1"
                @click="$router.push('/pages/target').catch(() => {})"
              >
                캠페인신청
              </a-menu-item>
              <a-menu-item
                class="font-menu1"
                key="apply:2"
                @click="$router.push('/pages/targethistory').catch(() => {})"
              >
                신청이력
              </a-menu-item>
            </a-sub-menu>

            <a-menu-item key="contact" class="s-menu-right">
              <div class="s-n-h-btn1" @click="showFlag = true">
                마케팅 문의하기
              </div>
            </a-menu-item>

            <a-sub-menu v-if="obj.displayName" class="s-menu-right">
              <span slot="title" class="submenu-title-wrapper">
                <img src="@/assets/images/v4-icon/user.svg" />&nbsp;<i
                  class="fas fa-caret-down"
                ></i
              ></span>
              <a-menu-item class="font-menu1" key="setting:0" disabled>
                <span class="font-menu2">{{ obj.displayName }}님</span>
              </a-menu-item>
              <a-menu-item
                class="font-menu1"
                key="setting:3"
                @click="$router.push('/pages/setting').catch(() => {})"
              >
                등급관리
              </a-menu-item>
              <a-menu-item
                class="font-menu1"
                key="setting:4"
                @click="$router.push('/pages/usersetting').catch(() => {})"
              >
                기준정보변경
              </a-menu-item>
              <a-menu-item
                class="font-menu1"
                key="setting:5"
                @click="$router.push('/pages/syssetting').catch(() => {})"
              >
                시스템설정
              </a-menu-item>
              <a-menu-item
                class="font-menu1"
                key="setting:6"
                @click="onLogOut()"
              >
                로그아웃
              </a-menu-item>
            </a-sub-menu>
          </a-menu>
        </div>
      </template>
    </a-page-header>
    <contact-modal
      :visible="showFlag"
      @closed="onCloseEvent()"
      @clicked="onClickEvent"
    ></contact-modal>
  </div>
</template>

<script>
import firebase from "firebase";
import auth from "@/auth/authService";
import ContactModal from "../../views/components/ContactModal/index";

import {
  getVersion,
  isMobile,
  showError,
  showSuccess,
  getCurrentTimeNew,
} from "../../views/components/fnc.js";

export default {
  props: {
    obj: {
      type: Object,
      default: null,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ContactModal,
  },
  data() {
    return {
      current: "",
      showFlag: false,
    };
  },
  computed: {
    getName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    getUid() {
      return this.$store.state.AppActiveUser.uid;
    },
    checkLogin() {
      return (
        this.$store.state.AppActiveUser.email && firebase.auth().currentUser
      );
    },
    getIncludeKeyword() {
      return this.$store.state.AppActiveUser.includekeyword;
    },
  },
  mounted() {
    if (
      firebase.auth().currentUser &&
      this.obj.email &&
      this.obj.displayName == "미확인"
    ) {
      alert("확인이 필요한 계정입니다. 1:1 문의로 채널 문의 부타드립니다.");
      this.onLogOut();
    }
  },
  methods: {
    onClickEvent(e) {
      //TO-DO
    },
    onCloseEvent() {
      this.showFlag = false;
    },
    onDownload() {
      var self = this;
      if (!this.checkLogin) {
        showSuccess({
          notify: self.$vs.notify,
          msg: "로그인하시면 다운로드 받을 수 있습니다.",
        });
      } else {
        window.open(
          "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2F(20.09.24)%E1%84%89%E1%85%B3%E1%84%90%E1%85%A9%E1%84%8B%E1%85%A5%E1%84%85%E1%85%B5%E1%86%BC%E1%84%8F%E1%85%B3_%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5_%E1%84%89%E1%85%A1%E1%86%BC%E1%84%8B%E1%85%B1%E1%84%82%E1%85%A9%E1%84%8E%E1%85%AE%E1%86%AF.pdf?alt=media&token=34a5758a-a2bf-48ee-997a-f0b0d2948094",
          "_blank"
        );
      }
    },
    ver() {
      return getVersion();
    },
    invokeChannelTalk() {
      ChannelIO("show");
    },
    onLogOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          auth.logOut();
          location.reload();
        });
    },
    onBack() {
      window.location.href = "https://storelink.io";
    },
  },
};
</script>

<style>
.navtop {
  background-color: white;
  width: 100%;
  -moz-transition: all 1s ease-in;
  /* WebKit */
  -webkit-transition: all 1s ease-in;
  /* Opera */
  -o-transition: all 1s ease-in;
  /* Standard */
  transition: all 1s ease-in;
}

.navtop.reverse {
  background-color: white;
  -moz-transition: all 1s ease-in;
  /* WebKit */
  -webkit-transition: all 1s ease-in;
  /* Opera */
  -o-transition: all 1s ease-in;
  /* Standard */
  transition: all 1s ease-in;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.navtop .ant-menu {
  background-color: white;
  -moz-transition: all 1s ease-in;
  /* WebKit */
  -webkit-transition: all 1s ease-in;
  /* Opera */
  -o-transition: all 1s ease-in;
  /* Standard */
  transition: all 1s ease-in;
}

.navtop.reverse .ant-menu {
  background-color: white;
  -moz-transition: all 1s ease-in;
  /* WebKit */
  -webkit-transition: all 1s ease-in;
  /* Opera */
  -o-transition: all 1s ease-in;
  /* Standard */
  transition: all 1s ease-in;
}

.navtop li.ant-menu-item-selected {
  color: black;
  font-weight: bold;
}

.navtop li.ant-menu-item:hover {
  color: black;
  font-weight: bold;
}

.navtop.reverse li.ant-menu-item-selected {
  color: #444444;
  font-weight: bold;
}

.navtop.reverse li.ant-menu-item:hover {
  color: #444444;
  font-weight: bold;
}

.ant-menu-horizontal {
  border-bottom: 0px solid #e8e8e8;
}

.navtop .ant-page-header {
  height: 90px;
  color: #333333;
}

.navtop.reverse .ant-page-header {
  height: 90px;
  color: #444444;
}

.ant-page-header-heading {
  margin-top: 8px;
}

.font-menu2 {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -1px;
  color: #000000;
}

.navtop .ant-menu-item,
.navtop .ant-menu-submenu {
  color: #333333;
  border-bottom: 0px solid white !important;
}

.navtop.reverse .ant-menu-item,
.navtop.reverse .ant-menu-submenu {
  color: #444444;
}

.ant-menu.ant-menu-vertical {
  background-color: white;
}

.ant-menu.ant-menu-vertical .ant-menu-item {
  color: black;
}

.navtop .ant-menu-submenu.ant-menu-submenu-horizontal,
.navtop .ant-menu-subment-open,
.navtop .ant-menu-submenu-title:hover {
  color: #333333;
}

.navtop.reverse .ant-menu-submenu.ant-menu-submenu-horizontal,
.navtop.reverse .ant-menu-subment-open,
.navtop.reverse .ant-menu-submenu-title:hover {
  color: #444444;
}

.ant-menu-item.ant-menu-item-active {
  font-weight: bold;
  color: #333333;
  border-bottom: 0px solid white !important;
}

.ant-menu.ant-menu-vertical .ant-menu-item.font-menu1 {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0px;
  /* letter-spacing: -0.88px; */
  color: #7d7d7d;
}

.ant-menu.ant-menu-vertical .font-menu1.ant-menu-item-active {
  color: black;
}

.s-top-menu1 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: -0.03em;
  padding-left: 10px;
  padding-right: 10px;
  color: #828282;
  cursor: pointer;
}

.s-top-menu1-div {
  width: 1px;
  height: 10px;
  background-color: #828282;
  margin-top: 5px;
}

li.s-menu-right {
  float: right;
}

.ant-page-header-heading-extra {
  width: 85%;
}

.ant-page-header-heading {
  width: 100%;
}

.s-n-h-btn1 {
  width: 131px;
  height: 39px;

  background: #0264fb;
  border-radius: 5px;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.03em;
  text-align: center;
  padding-top: 8px;
  margin-top: 5px;

  color: #ffffff;
}
</style>

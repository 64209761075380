<template>
<div class="mheader" style="position:fixed;z-index:10;width:100%;">
    <img class="h" @click="onBack()" src="@/assets/images/v4-icon/smlogo.png" />
    <div class="m" @click="visible=true"><img src="@/assets/images/v4-icon/menu.png" /></div>
    <a-drawer v-if="obj.displayName" :title="displayName" placement="right" :closable="true" :visible="visible" @close="onClose">
      
        <div class="mnenu">
            서비스안내
            <div class="smenu" style="margin-top:20px" @click="invokeChannelTalk()"> 고객센터</div>
            <div class="smenu" @click="onGotoNotification()"> 공지사항</div>
            <div class="smenu" @click="onGotoPrice()"> 이용요금</div>
            <a-divider />
        </div>

        <div class="mnenu" style="margin-top:130px;">
            상위노출
            <div class="smenu" style="margin-top:20px" @click="onNotSupport()"> 키워드상세분석</div>

            <a-divider />
        </div>

        <div class="mnenu" style="margin-top:80px;">
            캠페인
            <div class="smenu" style="margin-top:20px" @click="onTarget()"> 캠페인신청</div>
            <div class="smenu" @click="onHistory()">신청이력
                <a-divider />
            </div>

        </div>

        <div class="mnenu" style="margin-top:100px;">
            마이페이지
            <div class="smenu" style="margin-top:20px" @click="onSetting()">등급관리</div>
            <div class="smenu" @click="onUserSetting()">기준정보변경</div>
            <div class="smenu" @click="onSysSetting()">시스템설정</div>
            <div class="smenu" @click="onLogOut()" style="padding-bottom:50px;">로그아웃 </div>
        </div>
    </a-drawer>
    <a-drawer v-else title="메뉴" placement="right" :closable="true" :visible="visible" @close="onClose">
        <div class="mnenu" @click="onLogIn()">
            로그인
        </div>
        <div class="mnenu" @click="onRegister()">
            회원가입

        </div>
    </a-drawer>
</div>
</template>

<script>
import firebase from "firebase";
import auth from "@/auth/authService";
export default {
  props: {
    obj: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      current: "",
      visible: false
    };
  },
  computed: {
    checkLogin() {
      return (
        this.$store.state.AppActiveUser.email && firebase.auth().currentUser
      );
    }
  },

  methods: {
    onGotoPrice() {
      this.onClose();
      this.$router.push("/pages/price").catch(() => {});
    },
    onLogIn() {
      this.onClose();
      this.$router.push("/pages/login").catch(() => {});
    },
    onRegister() {
      this.onClose();
      this.$router.push("/pages/register").catch(() => {});
    },
    onUserSetting() {
      this.onClose();
      this.$router.push("/pages/usersetting").catch(() => {});
    },
    onSysSetting() {
      this.onClose();
      this.$router.push("/pages/syssetting").catch(() => {});
    },
    onSetting() {
      this.onClose();
      this.$router.push("/pages/setting").catch(() => {});
    },
    onHistory() {
      this.onClose();
      this.$router.push("/pages/targethistory").catch(() => {});
    },
    onTarget() {
      this.onClose();
      this.$router.push("/pages/target").catch(() => {});
    },
    onNotSupport() {
      this.onClose();
      this.$router.push("/pages/notsupport").catch(() => {});
    },
    invokeChannelTalk() {
      this.visible = false;
      ChannelIO("show");
    },
    displayName() {
      return this.obj.displayName + "님";
    },
    onClose() {
      this.visible = false;
    },
    onGotoNotification() {
      this.onClose();
      this.$router.push("/pages/info").catch(() => {});
    },
    onGotoFriday() {
      this.onClose();
      this.$router.push("/pages/mfriday").catch(() => {});
    },
    onLogOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          auth.logOut();
          location.reload();
        });
    },
    onBack() {
      window.location.href = "https://storelink.io";
    }
  }
};
</script>

<style>
.mheader {
  width: 100vw;
  height: 60px;
  background-color: white;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

.mnenu {
  height: 50px;
  padding-bottom: 15px;
  margin-top: 20px;
  text-align: left;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.67px;
  color: #000000;
}

.smenu {
  height: 30px;
  text-align: left;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.58px;
  color: #7d7d7d;
}

.mheader img.h {
  width: 100px;
  display: block;
  margin-left: 20px;
  padding-top: 20px;
}

.mheader .m {
  width: 70px;
  height: 60px;
  right: 0;
  top: 0;
  position: absolute;
}

.mheader .m > img {
  width: 20px;
  padding-top: 20px;
  float: right;
  margin-right: 20px;
}

.ant-drawer-body {
  padding-top: 0px !important;
}
</style>
